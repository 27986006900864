import React from "react";
import PropTypes from "prop-types";

import { ThemeContext } from "../../layouts";
import Blog from "../Blog";
import { graphql } from "gatsby";

const useCases = [
  {
    node: {
      excerpt:
        "As the pace innovation continues to accelerate, it's harder to keep up with today’s new breed of digital-savvy competitors. That’s why one of the world’s leading and most profitable recreational boating and lifestyle brands turned to Technicity to help their executives learn exactly how they could ride the wave of change in the digital age…",
      fields: { slug: "/boating/", prefix: "Boating" },
      frontmatter: {
        title: "Connected Boating Platform",
        category: "Recreational Boating",
        author: "",
        cover: {
          children: [
            {
              __typename: "ImageSharp",
              fluid: {
                // base64:
                //   "data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAMFAgT/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/2gAMAwEAAhADEAAAAW7lvZonAV//xAAbEAEAAgIDAAAAAAAAAAAAAAABAAIREwMSIf/aAAgBAQABBQLSZ1jCnUORhdzZ9//EABYRAQEBAAAAAAAAAAAAAAAAAAARAf/aAAgBAwEBPwGYj//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/Aar/xAAZEAADAAMAAAAAAAAAAAAAAAAAAREQIUH/2gAIAQEABj8C01CUiSOZ/8QAHRAAAgIBBQAAAAAAAAAAAAAAAAERITFBUWFxkf/aAAgBAQABPyGjM74IzRHD3HyByynXgaakpeg0f//aAAwDAQACAAMAAAAQTA//xAAWEQEBAQAAAAAAAAAAAAAAAAABACH/2gAIAQMBAT8QWgmX/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8QHP8A/8QAHBABAAIDAAMAAAAAAAAAAAAAAQARITFBUWHh/9oACAEBAAE/EEbvdC/UGPeGjAQXbQtx6vCtVuVSq5p6m7VwZZ//2Q==",
                aspectRatio: 2.2222222222222223,
                src:
                  "/static/c05f925825606e19a957283ac76dc0db/97e83/photo-1465070845512-2b2dbdc6df66.jpg",
                srcSet:
                  "/static/c05f925825606e19a957283ac76dc0db/050fa/photo-1465070845512-2b2dbdc6df66.jpg 200w,\n/static/c05f925825606e19a957283ac76dc0db/84acf/photo-1465070845512-2b2dbdc6df66.jpg 400w,\n/static/c05f925825606e19a957283ac76dc0db/97e83/photo-1465070845512-2b2dbdc6df66.jpg 800w,\n/static/c05f925825606e19a957283ac76dc0db/b9bda/photo-1465070845512-2b2dbdc6df66.jpg 1200w,\n/static/c05f925825606e19a957283ac76dc0db/7a443/photo-1465070845512-2b2dbdc6df66.jpg 1464w",
                srcWebp:
                  "/static/c05f925825606e19a957283ac76dc0db/77039/photo-1465070845512-2b2dbdc6df66.webp",
                srcSetWebp:
                  "/static/c05f925825606e19a957283ac76dc0db/c5f10/photo-1465070845512-2b2dbdc6df66.webp 200w,\n/static/c05f925825606e19a957283ac76dc0db/30f75/photo-1465070845512-2b2dbdc6df66.webp 400w,\n/static/c05f925825606e19a957283ac76dc0db/77039/photo-1465070845512-2b2dbdc6df66.webp 800w,\n/static/c05f925825606e19a957283ac76dc0db/5d04f/photo-1465070845512-2b2dbdc6df66.webp 1200w,\n/static/c05f925825606e19a957283ac76dc0db/bf567/photo-1465070845512-2b2dbdc6df66.webp 1464w",
                sizes: "(max-width: 800px) 100vw, 800px"
              }
            }
          ]
        }
      }
    }
  }
];

const Article = props => {
  const { children, theme, posts } = props;

  console.log(props);

  return (
    <React.Fragment>
      <article className="article">{children}</article>
      <div>
        <ThemeContext.Consumer>
          {t => <Blog posts={posts ? posts : []} theme={theme} />}
        </ThemeContext.Consumer>
      </div>
      {/* --- STYLES --- */}
      <style jsx>{`
        .article {
          padding: ${theme.space.inset.default};
          margin: 0 auto;
        }
        @from-width tablet {
          .article {
            padding: ${`calc(${theme.space.default}) calc(${theme.space.default} * 2)`};
            max-width: ${theme.text.maxWidth.tablet};
          }
        }
        @from-width desktop {
          .article {
            padding: ${`calc(${theme.space.default} * 2 + 90px) 0 calc(${theme.space.default} * 2)`};
            max-width: ${theme.text.maxWidth.desktop};
          }
        }
      `}</style>
    </React.Fragment>
  );
};

Article.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired,
  posts: PropTypes.array.isRequired
};

export default Article;
